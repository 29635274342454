import { useContext, useEffect, useRef, useState } from "react";
import { OverlayManagerContext } from "./Overlay-wrapper";
import { OverlayList } from "./Overlay-list";
import { OVERLAY_MANAGER } from "./Select";
import { Feature } from "ol";
import { Button } from "antd";
import { GlobalSettingsContext } from "../../Settings";
import { Circle, Style } from "ol/style";
import { Point } from "ol/geom";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { FeatureLike } from "ol/Feature";
import { doDarkMagic, strToFilter } from "../../data-layers/Style-generator";
import RenderFeature from "ol/render/Feature";
import { StyleLayerProp, getStyleItems } from "../../data-layers/Style-layer.repository";
import { LineStyle, PointStyle } from "../../store/Layers.repository";
import { take } from "rxjs";
import { createTitle } from "../../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { FEATURE_LIST } from "../../data-layers/Data-layers-new-layer-manager";

const ITEMS_PER_PAGE = 8;

export const getStyle = (feature: Feature | RenderFeature, internal: any): any | undefined => {
	let styleItems: StyleLayerProp[] = [];

	getStyleItems()
		.pipe(take(1))
		.subscribe((sl) => {
			styleItems = sl;
		});

	if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
		const names = Array.from(new Set(styleItems.map((s) => s.name)));
		const styles = (feature.getGeometry()?.getType() === "Point" || feature.getGeometry()?.getType() === "Polygon" ? internal["points"] : internal["lines"])
			.filter((s: LineStyle | PointStyle) =>
				styleItems
					.filter((styleItem) => styleItem.visible)
					.map((styleItem) => styleItem.layers)
					.flat()
					.find((l) => l.visible && s.layerName === l.name)
			)
			.sort((a: LineStyle, b: LineStyle) => {
				const aVal = names.indexOf(a.groupName);
				const bVal = names.indexOf(b.groupName);
				if (aVal > bVal) {
					return -1;
				}

				if (aVal < bVal) {
					return 1;
				}

				return 0;
			});

		const style = styles.find(
			(s: any) =>
				s.source === feature.get("layer") &&
				(!s.filter ||
					doDarkMagic(feature, { ...s, filter: strToFilter(s.filter), style: true }) ||
					(s.filter === "other" &&
						styles.filter((sty: any) => sty.groupName === s.groupName && doDarkMagic(feature, { ...sty, filter: strToFilter(sty.filter), style: true })).length < 2))
		);
		if (!style) {
			return styles.find((s: any) => s.filter === "other");
		}
		return style;
	} else if (feature instanceof Feature) {
		return feature.get("internal")?.["style"];
	} else {
		return undefined;
	}
};

export const getColor = (feature: Feature | FeatureLike) => {
	const style = feature.get("internal")?.["style"] as Style[];
	return style?.length > 0
		? style
				.map((s) => {
					let color = (s.getStroke() ?? s.getFill())?.getColor()?.toString();
					if (!color && s.getImage() instanceof Circle) {
						color = (s.getImage() as Circle)?.getFill()?.getColor()?.toString() ?? "";
					}
					return color;
				})
				.find((s) => !!s)
		: "";
};

export const Overlay = () => {
	const [item, setItem] = useState(0);
	const [page, setPage] = useState(0);
	const { context } = useContext(OverlayManagerContext);
	const { system_code } = useContext(GlobalSettingsContext);
	const feature = context.features[item] as Feature | RenderFeature;

	const textRef = useRef<HTMLElement | any>(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const [showMoreButton, setShowMoreButton] = useState(false);
	const [dots, setDots] = useState(false);

	useEffect(() => {
		setItem(0);
		setPage(0);
	}, [context]);

	const getIcons = (features: Feature[] | FeatureLike[]) => {
		return features.map((f, i) => {
			const internal = f.get("internal");
			if ((internal?.["originalTable"] || internal?.["table"]) === "kafkamessages_cms" || f?.get("layer").includes("kafkamessages_cms")) {
				const temp = f.get("t1");
				return f.get("status") === "active" ? (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={f.get("id") + "-" + i}
						className={`bg-[#4b4d4f] p-6 text-white block font-semibold rounded-full relative ${
							context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500" : ""
						}`}
					>
						<div className="absolute inset-0 flex items-center justify-center">
							<p>{temp ? `${temp > 0 ? "+" : ""}${Number(temp).toFixed(0)}°` : ""}</p>
						</div>
					</button>
				) : (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={f.get("id") + "-" + i}
						className={`bg-[#c1c2c2] p-6 text-white block font-semibold rounded-full relative ${
							context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500" : ""
						}`}
					>
						<div className="absolute inset-0 flex items-center justify-center">
							<p>-</p>
						</div>
					</button>
				);
			}

			const style = getStyle(f, internal);
			const url: string = internal?.["icon"] ? internal["icon"] : Array.isArray(style) ? style?.find((s: any) => !!s.piktogram)?.piktogram : style?.piktogram;
			return url || (f instanceof Feature ? f.getGeometry() instanceof Point : (f as any).getType() === "Point") ? (
				url ? (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={f.get("id") + "-" + i}
						className={`block ${url.split("/")[url.split("/").length - 1].split("_")[0] === "c" ? "rounded-full" : "rounded-md"} ${
							context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500 overflow-hidden" : ""
						}`}
					>
						<img src={url} alt="icon" />
					</button>
				) : (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={f.get("id") + "-" + i}
						className={`flex items-center justify-center h-12 w-12 rounded-full ${
							context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500" : ""
						}`}
					>
						<div
							style={{
								background: style?.color ?? getColor(f),
							}}
							className="h-4 w-4 rounded-full"
						></div>
					</button>
				)
			) : f.get("layer").replace("public.", "") === "kafkamessages_uvis" && (f.getGeometry()?.getType() === "Point" || f.getGeometry()?.getType() === "Polygon") ? (
				<div
					onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
					key={f.get("id") + "-" + +i}
					className={`h-12 w-12 relative shrink-0 flex items-center justify-center ${
						context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500 rounded-full" : ""
					}`}
				>
					<div
						style={{
							background: style?.color ?? getColor(f),
						}}
						className="h-2 w-2 rounded-full absolute"
					></div>
					<div
						style={{
							background: style?.color ?? getColor(f),
							opacity: 0.5,
						}}
						className="h-12 w-12 rounded-full absolute"
					></div>
				</div>
			) : (
				getLineIcon(f, i, style)
			);
		});
	};

	const getLineIcon = (feature: Feature | FeatureLike, i: number, style: any | undefined) => {
		switch (style?.stroke_style) {
			case "contour":
				return (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={feature.get("id") + "-" + +i}
						className={`flex select-none rounded-full relative ${context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500" : ""}`}
					>
						{
							<div className="h-12 w-12 flex flex-col gap-1 justify-center">
								<div
									style={{
										background: style.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
								<div
									style={{
										background: style.color ?? getColor(feature),
									}}
									className="w-full h-1.5 rounded-full"
								></div>
							</div>
						}
					</button>
				);
			case "dot":
				return (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={feature.get("id") + "-" + +i}
						className={`flex select-none rounded-full ${context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500" : ""}`}
					>
						<div className="relative h-12 w-12 shrink-0">
							{
								<div className="h-12 w-12 flex items-center justify-center gap-1">
									{[1, 2, 3, 4, 5].map((i) => (
										<div
											key={i}
											style={{
												background: style.color ?? getColor(feature),
											}}
											className="h-2 w-2 rounded-full shrink-0"
										></div>
									))}
								</div>
							}
						</div>
					</button>
				);
			default:
				return (
					<button
						onClick={() => setItem(i + page * ITEMS_PER_PAGE)}
						key={feature.get("id") + "-" + +i}
						className={`flex select-none rounded-full ${context.features.length > 1 && item === i + page * ITEMS_PER_PAGE ? "ring-2 ring-npp-blue-500" : ""}`}
					>
						<div className="relative h-12 w-12 shrink-0">
							{
								<div className="h-12 w-12 flex flex-col justify-center">
									<div
										style={{
											background: style?.color ?? getColor(feature),
										}}
										className="w-full h-1.5 rounded-full"
									></div>
								</div>
							}
						</div>
					</button>
				);
		}
	};

	const getTitle = () => {
		if (!feature) return "";
		const lr = feature.get("linear_reference");

		if (feature.get("layer").includes("detour")) {
			const internal = feature.get("internal");
			const [style] = getStyle(feature, internal);

			return `Apbraukšanas maršruts <br> ${
				createTitle(feature, style.popup_title) ?? style
					? (style.layerName + " " ?? "") + (lr ?? "")
					: undefined ?? internal["title"] ?? (internal?.["styleName"] ?? internal?.["layerName"]) + (lr ? "<br>" + lr : "")
			}`;
		}

		if (feature instanceof RenderFeature || !feature.get("internal")?.["style"]) {
			let tempf = feature;
			if (feature.get("linked_to")) {
				const linkedFeature = FEATURE_LIST[feature.get("linked_to") + "-" + feature.get("layer") + "-LineString"]?.feature;

				if (linkedFeature) {
					tempf = linkedFeature;
				}
			}
			const style = getStyle(tempf, tempf.get("internal"));
			if (style) {
				const hideDots = !isExpanded && style.name.length > (context.features.length > 1 ? 21 : 31);
				if (dots === hideDots) {
					setDots(!dots);
				}
				const title = style?.popup_title ? createTitle(tempf, style.popup_title) : style ? (hideDots ? style.name + ".." : style.name) + (lr ? "<br>" + lr : "") : "";
				return title;
			}
			return "";
		} else if (feature instanceof Feature) {
			const internal = feature.get("internal");
			return internal["title"] ?? (internal?.["styleName"] ?? internal?.["layerName"]) + " " + (lr ?? "");
		}
		return "";
	};

	const getButton = () => {
		const value = feature.get("layer") !== "features" ? feature.get("layer") : feature.get("internal")["table"];

		if (value?.includes("kafkamessages_notplannedevent")) {
			return (
				<a target="_blank" rel="noreferrer" href={`${window.location.origin}/unexpected_events/${feature.get("kafka_id")}`}>
					<Button type="primary" className="mt-6 ml-2 mb-4">
						Slēgt vai rediģēt brīdinājumu
					</Button>
				</a>
			);
		} else if (value?.includes("kafkamessages_staticobject")) {
			return (
				<a target="_blank" rel="noreferrer" href={`${window.location.origin}/static_objects/${feature.get("kafka_id")}`}>
					<Button type="primary" className="mt-6 ml-2 mb-4">
						Rediģēt
					</Button>
				</a>
			);
		} else if (value?.includes("kafkamessages_plannedevent")) {
			return (
				<a target="_blank" rel="noreferrer" href={`${window.location.origin}/planned_events/${feature.get("kafka_id")}`}>
					<Button type="primary" className="mt-6 ml-2 mb-4">
						Rediģēt
					</Button>
				</a>
			);
		}
		return <></>;
	};

	const getAttributes = () => {
		const internal = feature.get("internal");
		return internal?.attributes ?? getStyle(feature, feature?.get("internal"))?.attributes;
	};

	const checkOverflow = () => {
		if (!isExpanded && textRef.current && (textRef.current.scrollHeight > textRef.current.clientHeight) !== showMoreButton) {
			const isOverflow = textRef.current.scrollHeight > textRef.current.clientHeight;

			setShowMoreButton(isOverflow);
		}
	};

	setTimeout(() => {
		checkOverflow();
	}, 50);

	useEffect(() => {
		setIsExpanded(false);
	}, [feature]);

	return (
		<section id="overlay" className="p-2 rounded w-[90vw] sm:w-[30em] border-2 border-npp-grey-500 bg-white flex z-50 shadow-md">
			<div className="p-2 space-y-2 shrink-0 block mt-1">
				{context.features.length > ITEMS_PER_PAGE && (
					<button
						onClick={() => setPage(0 > (page - 1) * ITEMS_PER_PAGE ? Math.floor(context.features.length / ITEMS_PER_PAGE) : page - 1)}
						className={`rounded-full h-12 w-12 flex items-center justify-center`}
					>
						<CaretUpOutlined rev={undefined} />
					</button>
				)}
				{context.features.length > 0 && getIcons(context.features.slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE))}
				{context.features.length > ITEMS_PER_PAGE && (
					<button
						onClick={() => setPage(context.features.length > (page + 1) * ITEMS_PER_PAGE ? page + 1 : 0)}
						className={`rounded-full h-12 w-12 flex items-center justify-center`}
					>
						<CaretDownOutlined rev={undefined} />
					</button>
				)}
			</div>
			<div className="flex-grow max-w-[17em] sm:max-w-[25em] p-2">
				<div className="flex min-w-0 wrap items-center">
					<div className="relative mr-auto text-lg font-semibold leading-tight text-npp-grey-500">
						<div
							ref={textRef}
							className={`${!isExpanded && "max-h-[45px]"} text-ellipsis break-all overflow-y-hidden ${
								context.features.length > 1 ? "sm:w-48" : "sm:w-[19rem]"
							} whitespace-pre-line`}
						>
							<p dangerouslySetInnerHTML={{ __html: getTitle() }}></p>
						</div>
						{!isExpanded && showMoreButton && dots && <p className="absolute bottom-0 -right-2">..</p>}
					</div>
					<div className="flex items-center select-none flex-shrink-0 mb-auto">
						{context.features.length > 1 && (
							<>
								<button onClick={() => setItem(item - 1 < 0 ? context.features.length - 1 : item - 1)} className="inline-flex">
									<span className="material-icons text-3xl">arrow_left</span>
								</button>
								<div className="rounded-md bg-red-600 h-5 min-w-[2em] px-2 font-extrabold text-white flex items-center">
									{item + 1} / {context.features.length}
								</div>
								<button onClick={() => setItem(item + 1 > context.features.length - 1 ? 0 : item + 1)} className="inline-flex">
									<span className="material-icons text-3xl">arrow_right</span>
								</button>
							</>
						)}
						<button
							className="inline-flex"
							onClick={() => {
								document.getSelection()?.empty();
								OVERLAY_MANAGER.setPosition(undefined);
							}}
						>
							<span className="material-icons">close</span>
						</button>
					</div>
				</div>
				{showMoreButton && (
					<button className="flex gap-1 items-center text-sm font-normal focus:outline-none select-none mt-0.5" onClick={() => setIsExpanded(!isExpanded)}>
						<p className="underline">{isExpanded ? "Rādīt mazāk" : "Rādīt visu"}</p>
						<span className={`material-icons text-lg ${isExpanded && "rotate-180"}`}>arrow_drop_down</span>
					</button>
				)}

				{feature && <OverlayList feature={feature} attributes={getAttributes()}></OverlayList>}
				{system_code === "SIPR" && feature && getButton()}
			</div>
		</section>
	);
};

import { createContext, useEffect, useState } from "react";
import { MapMarkerFeatureListItem } from "./Map-marker-feature-list-item";
import { Feature } from "ol";
import { Button } from "antd";
import { Point } from "ol/geom";
import * as ol from "ol/util";
import { AddProjection, MAP, MARKER_LAYER } from "./Map-marker";
import WKT from "ol/format/WKT";
import { createEmpty, extend } from "ol/extent";
import { BehaviorSubject, filter, take } from "rxjs";

interface Item {
	street?: string;
	start?: string;
	end?: string;
	geom: string;
	address?: string;
	openlr: any;
	detour: boolean;
	twoWay?: boolean;
	turnedAround?: boolean;
}

interface Props {
	options: { readOnly?: boolean; sticky?: boolean; onlyOne?: boolean; tag?: string; detour?: boolean };
	onChange(items: Item[]): unknown;
	value?: Item[];
}

let propItems: any;
export const mapSize$ = new BehaviorSubject<[number, number]>([0, 0]);

const defaultContextValues: {
	readOnly: boolean;
	sticky: boolean;
	tag?: string;
	detour: boolean;
} = { readOnly: false, sticky: true, tag: undefined, detour: false };

export const FeatureListContext = createContext(defaultContextValues);

export const MapMarkerFeatureList = (props: Props) => {
	const [items, setItems] = useState<Feature[]>([]);
	const [context, setContext] = useState(defaultContextValues);

	setTimeout(() => {
		window.dispatchEvent(new Event("resize"));
	}, 100);

	useEffect(() => {
		setContext({
			readOnly: props.options.readOnly ?? false,
			sticky: props.options.sticky ?? true,
			tag: props.options.tag,
			detour: !!props.options.detour,
		});
	}, [props.options.readOnly, props.options.sticky, props.options.tag, props.options.detour]);

	useEffect(() => {
		const source = MARKER_LAYER.getSource()!;
		source.on("change", () => {
			const features = source.getFeatures().filter((f) => !!f.get("detour") === !!props.options.detour);

			setItems(features);
			const data: Item[] = features.map((f) => {
				return {
					street: f.get("street"),
					start: f.get("start")?.toString(),
					end: f.get("end")?.toString(),
					geom: new WKT().writeGeometry(f.getGeometry()!, {
						dataProjection: "EPSG:3059",
						featureProjection: "EPSG:3857",
					}),
					address: f.get("address"),
					openlr: {
						binary: f.get("openlr")?.[0]?.["binary"],
						latitude: f.get("openlr")?.[0]?.["latitude"],
						longitude: f.get("openlr")?.[0]?.["longitude"],
						locationreference_xml: f.get("openlr")?.[0]?.["pointlocationreference_xml"] ?? f.get("openlr")?.[0]?.["linelocationreference_xml"],
					},
					error: !!f.get("error"),
					detour: !!f.get("detour"),
					twoWay: !!f.get("twoWay"),
					turnedAround: !!f.get("turnedAround"),
				};
			});

			console.log(data);
			props.onChange?.(data);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (propItems && JSON.stringify(propItems) === JSON.stringify(props.value)) return;

		const source = MARKER_LAYER.getSource()!;

		propItems = props.value;

		if (propItems && propItems.length > 0) {
			source.removeFeatures(source.getFeatures().filter((f) => f.get("detour") === !!props.options.detour));

			AddProjection();
			const features = propItems
				?.map((i: Item) => {
					console.log("i:", i);
					const feature = new WKT().readFeature(i.geom, {
						dataProjection: "EPSG:3059",
						featureProjection: "EPSG:3857",
					});
					feature.setProperties({
						street: i.street,
						start: i.start,
						end: i.end,
						address: i.address,
						detour: !!props.options.detour,
						twoWay: !!i.twoWay,
						turnedAround: !!i.turnedAround,
						openlr: [
							{
								binary: i.openlr?.binary,
								latitude: i.openlr?.latitude,
								longitude: i.openlr?.longitude,
								pointlocationreference_xml: i.openlr?.locationreference_xml,
							},
						],
						DONT_UPDATE: true,
					});
					return feature;
				})
				.filter((f: Feature) => {
					const coords = ((f.getGeometry() as any).flatCoordinates as number[]).toString();
					return !source.getFeatures().find((cf) => ((cf.getGeometry() as any).flatCoordinates as number[]).toString() === coords);
				});
			if (features.length > 0) {
				mapSize$
					.pipe(
						filter((size) => size[0] !== 0),
						take(1)
					)
					.subscribe((size) => {
						const extent = createEmpty();
						features.forEach((feature: Feature) => extend(extent, feature.getGeometry()!.getExtent()));
						MAP.getView().fit(extent, { maxZoom: 10, size: size });
					});
			}
			source.addFeatures(features);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.value]);

	const addFeature = () => {
		const source = MARKER_LAYER.getSource()!;
		source.addFeature(
			new Feature({
				error: true,
				silent_error: true,
				geometry: new Point([0, 0]),
				detour: !!props.options.detour,
			})
		);
	};

	useEffect(() => {
		MAP.on("change:size", (e) => {
			const size = e.target.getSize();
			if (size && size[0] !== 0) {
				mapSize$.next(size);
			}
		});
	}, []);

	return (
		<FeatureListContext.Provider value={context}>
			<ul className="space-y-8 max-h-96 overflow-y-auto">
				{items
					.sort((a, b) => Number(ol.getUid(a)) - Number(ol.getUid(b)))
					.map((i) => (
						<MapMarkerFeatureListItem key={ol.getUid(i) + i.get("trigger")} feature={i}></MapMarkerFeatureListItem>
					))}
			</ul>
			{(!props.options.onlyOne || (props.options.onlyOne && items.length < 1)) && (
				<Button onClick={addFeature} disabled={context.readOnly} type="primary" className="mt-4">
					+
				</Button>
			)}
		</FeatureListContext.Provider>
	);
};

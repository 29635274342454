import LayerGroup from "ol/layer/Group";
import { TableItem } from "../../Data-layers-new-layer-manager";
import VectorTileLayer from "ol/layer/VectorTile";
import VectorTileSource from "ol/source/VectorTile";
import MVT from "ol/format/MVT";
import { Feature, Map, VectorTile } from "ol";
import { addTempPoint, doDarkMagic, getPlannedStyle, getPointShadow, getPointStyle, strToFilter } from "../../Style-generator";
import { COOLER_CLUSTER_SOURCE } from "./Cooler-cluster";
import { LayerStyle, PointStyle } from "../../../store/Layers.repository";
import { VISIBLE_STYLE_LAYERS, slogger } from "./Cooler-cluster-style";
import { StyleLayerProp } from "../../Style-layer.repository";
import { Point } from "ol/geom";
import { FeatureLike } from "ol/Feature";

export let COOLER_LAYER_CACHE: { [id: string]: Feature[] } = {};
export let COOLER_LAYER_CACHE_FEATURE_ID: { [id: string]: string[] } = {};

export const clearCache = () => {
	COOLER_LAYER_CACHE = {};
	COOLER_LAYER_CACHE_FEATURE_ID = {};
	COOLER_CLUSTER_SOURCE.clear();
};

export const findStyle = (item: { table: string }, f: Feature, styleDef: PointStyle) => {
	if (item.table === "kafkamessages_cms") {
		return [...addTempPoint(f), getPointShadow()];
	}
	if (item.table === "kafkamessages_plannedevent" && f.get("status") === "PLANNED") {
		f.set("tempOpacity", 0.75, true);
		return [...getPointStyle(styleDef), ...getPlannedStyle()];
	}
	return getPointStyle(styleDef);
};

export const createTitle = (f: FeatureLike, popupTitle: string) => {
	if (!popupTitle) return undefined;
	popupTitle = popupTitle.replaceAll("/n", "<br>").replaceAll("\n", "<br>");
	const key = popupTitle.match(/\{\{(.*?)\}\}/g)?.[0];
	if (!key) return undefined;
	const featureKey = key.replaceAll("{", "").replaceAll("}", "");

	return popupTitle.replaceAll(key, f.get(featureKey));
};

export const createCoolerClusterSource = (url: string, item: TableItem, id: string, map: Map, refresh?: boolean) => {
	const source = new VectorTileSource({
		transition: 0,
		url,
		projection: "EPSG:3857",
		format: new MVT({
			featureClass: Feature as any,
		}),
		wrapX: false,
		cacheSize: 16,
		overlaps: false,
		tileLoadFunction: (tile, tileURL) => {
			const t = tile as VectorTile<Feature>;
			t.setLoader(function (extent, r, projection) {
				fetch(tileURL).then(function (response) {
					response.arrayBuffer().then(function (data) {
						const format = t.getFormat();
						const features = format.readFeatures(data, {
							extent: extent,
							featureProjection: projection,
						}) as Feature[];
						const ids = COOLER_LAYER_CACHE_FEATURE_ID[id] ?? [];
						const zoom = map.getView().getZoom()!;
						const newFeats = features.filter((f) => {
							const fid = id + "-" + f.get("id") + "-" + f.get("layer") + "-" + f.get("linked_to");
							const type = f.getGeometry()?.getType();

							if (type === "Point") {
								const cacheFeature = COOLER_LAYER_CACHE[id]?.[ids.indexOf(fid)];
								if (cacheFeature) {
									//Update point geometry in big zoom
									if (!source.get("refresh") && zoom > 13 && cacheFeature && !cacheFeature.get("internal")["updated"]) {
										const geom = cacheFeature.getGeometry() as unknown as Point;
										//@ts-expect-error override flatCoordinates
										geom.flatCoordinates = (f.getGeometry() as Point).getFlatCoordinates();
										cacheFeature.set("internal", { ...cacheFeature.get("internal"), updated: true }, true);
									}

									if ((source.get("refresh") && !cacheFeature?.get("justUpdated")) || cacheFeature.get("removed")) {
										const [styleDef] = cacheFeature.get("internal")["pointStyles"];
										if (styleDef) {
											const style = findStyle({ table: f.get("layer").replace("public.", "") }, f, styleDef);
											const internal = { ...cacheFeature.get("internal"), style: style ? (Array.isArray(style) ? style : [style]) : undefined };
											cacheFeature.set("internal", internal, true);
										}
										cacheFeature.setProperties({ ...f.getProperties(), justUpdated: true, removed: false }, true);
									}
								}
							}

							if (type !== "Point" || ids.indexOf(fid) !== -1) return false;

							let layerStyleDef: { pointStyles: PointStyle[]; layer: LayerStyle } | undefined = undefined;
							for (let i = 0; i < item.layers.length; i++) {
								const layer = item.layers[i];
								const styleDefs = layer.points.filter(
									(p) => f.get("layer") === p.source && (!p.filter || doDarkMagic(f, { filter: strToFilter(p.filter), style: true as any, styleDef: undefined as any }))
								);
								if (styleDefs.length > 0) {
									layerStyleDef = { pointStyles: styleDefs, layer };
									break;
								}
							}

							if (layerStyleDef) {
								const styleDef = layerStyleDef.pointStyles.find(
									(ps) => !!VISIBLE_STYLE_LAYERS.find((sl) => sl.name === ps.groupName && !!sl.layers.find((l) => l.visible && l.name === ps.layerName))
								);

								const style = styleDef ? findStyle({ table: f.get("layer").replace("public.", "") }, f, styleDef) : undefined;
								const layer = layerStyleDef.layer;

								f.setProperties(
									{
										id: fid,
										internal: {
											style: style ? (Array.isArray(style) ? style : [style]) : undefined,
											icon: styleDef?.piktogram,
											originalTable: f.get("layer").replace("public.", ""),
											table: item.table,
											attributes: [...layer.attributes.split(",")],
											layerName: layer.name,
											styleName: styleDef?.name,
											pointStyles: layerStyleDef.pointStyles,
											title: styleDef?.popup_title ? createTitle(f, styleDef.popup_title) : undefined,
										},
										justUpdated: true,
									},
									true
								);
								return true;
							}
							return false;
						}) as Feature[];

						if (newFeats.length > 0) {
							COOLER_LAYER_CACHE[id] = [...newFeats, ...(COOLER_LAYER_CACHE[id] ?? [])];
							COOLER_LAYER_CACHE_FEATURE_ID[id] = [...newFeats.map((f) => f.get("id")), ...(COOLER_LAYER_CACHE_FEATURE_ID[id] ?? [])];
							COOLER_CLUSTER_SOURCE.addFeatures(newFeats);
							slogger.next(COOLER_LAYER_CACHE_FEATURE_ID);
						}
						t.setFeatures([]);
					});
				});
			});
		},
	});
	source.set("refresh", !!refresh, true);
	return source;
};

export const addCoolerClusterGroup = (tableList: TableItem[], map: Map, styleItems: StyleLayerProp[]) => {
	const clusterLayerGroup = new LayerGroup({
		properties: {
			type: "cluster",
		},
		layers: tableList.map((item) => {
			const url = item.merged_tiles ?? item.url;
			const styleLayers = item.layers.filter((l) => l.clustered && styleItems.find((i) => i.visible && i.layers.find((il) => il.id === l.name + "-" + l.id)?.visible));

			const layer = new VectorTileLayer({
				zIndex: -1,
				renderOrder: null as unknown as any, //Wrong typedef workaround, null disables ordering
				className: "",
				properties: {
					id: item.table,
					url,
					table: item.table,
					clustered: true,
					use_uvis_popups: item.use_uvis_popups,
					layers: item.layers,
					type: "data",
				},
				source: createCoolerClusterSource(url, item, item.table, map),
				style: () => undefined,
				visible: !!styleLayers.find((sl) => sl.visible),
			});

			return layer;
		}),
	});

	map.addLayer(clusterLayerGroup);
};

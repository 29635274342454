import { Feature, Map } from "ol";
import { setFeatureState } from "ol-mapbox-style";
import RenderFeature from "ol/render/Feature";
import { useEffect, useRef } from "react";
import { Style } from "ol/style";
import { getPointHighlight } from "./Highlight-style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { findStyle } from "../data-layers/layer-switcher/Cluster/Cooler-cluster-group";
import { LineString, Point } from "ol/geom";
import { getStyle } from "./overlay/Overlay";
import { FeatureLike } from "ol/Feature";
import { FEATURE_LIST } from "../data-layers/Data-layers-new-layer-manager";

export const HIGHLIGHT_LAYER = new VectorLayer({
	properties: { name: "Highlight", id: "highlight" },
	source: new VectorSource(),
});

export const FeatureHighlighter = ({ map }: { map: Map }) => {
	const features = useRef<(Feature | RenderFeature)[]>([]);

	useEffect(() => {
		if (
			!map ||
			map
				.getLayers()
				.getArray()
				.find((l) => l.get("id") === "highlight")
		)
			return;

		map.addLayer(HIGHLIGHT_LAYER);
	}, [map]);

	useEffect(() => {
		if (!map) return;

		const removeCurrHighlight = () => {
			HIGHLIGHT_LAYER.getSource()?.clear(true);
			features.current.forEach((f) => {
				const props = f.getProperties();
				if (props) {
					const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
					if (layer) {
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"] + "-LineString", source: props["layer"] },
							undefined
						);
					}
				}
			});
			features.current = [];
		};

		const keyEvent = map.on("pointermove", (e) => {
			const [feat] = map.getFeaturesAtPixel(e.pixel, { hitTolerance: 2 });

			if (feat && features.current.find((f) => f.getId() === feat.getId())) return;

			if (feat && !feat.get("features") && feat.getProperties()["internal"]) {
				removeCurrHighlight();
				const highlightPoint = (feature: FeatureLike) => {
					if (feature instanceof Feature && !features.current.find((f) => f.getId() === feature.getId()) && feature.getGeometry() instanceof Point) {
						const styleDef = getStyle(feature, feature.get("internal"));
						const style = findStyle({ table: feature.get("layer").replace("public.", "") }, feature, styleDef);
						const clone = feature.clone();
						//@ts-expect-error
						clone.id_ = feature.getId();
						clone.setStyle(style?.map((s: Style) => ((s as any).isShadow_ ? getPointHighlight() : s)));
						HIGHLIGHT_LAYER.getSource()?.addFeature(clone);
						features.current = [...features.current, feat];
					}
				};
				highlightPoint(feat);
				const props = feat.getProperties();
				if (feat instanceof RenderFeature || !feat?.get("internal")?.["style"]) {
					const layer = map.getAllLayers().find((l) => l.get("table") === props["internal"]["table"] && l.get("mapbox-source"));
					if (layer) {
						features.current = [...features.current, feat];
						setFeatureState(
							layer as any,
							{ id: (props["linked_to"] ?? props["kafka_id"] ?? props["id"]) + "-" + props["layer"] + "-LineString", source: props["layer"] },
							{ highlighted: true }
						);
					}
					if (feat.getGeometry() instanceof LineString) {
						const linkedFeature = FEATURE_LIST[props["linked_to"] + "-" + props["layer"] + "-Point"]?.feature;
						highlightPoint(linkedFeature);
					}
				}
			} else {
				removeCurrHighlight();
			}
		});

		return () => {
			map.un("pointermove", keyEvent.listener);
		};
	}, [map]);

	return <></>;
};

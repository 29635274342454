import { useState } from "react";
import Checkbox1Component from "../../shared/UI kit/checkbox-1";
import { StyleLayerItem } from "../Style-layer.repository";

import { DataLayersOpacitySwitcher } from "../Data-layers-opacity-switcher";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";

export const LayerSwitcherItem = ({ item, onChange, zoom }: { item: StyleLayerItem; onChange: any; zoom: number }) => {
	const [t] = useTranslation();

	const [trigger, setTrigger] = useState(false);
	const [checked, setChecked] = useState(item.visible);
	const styleDef = (item.lines.length > 0 ? item.lines : item.points)?.[0];
	const inactive = styleDef ? zoom > styleDef.maxZoom! + 1 || zoom < styleDef.minZoom! + 1 : true;

	return (
		<li className="text-sm flex gap-[0.6rem] items-center ml-14">
			<Tooltip
				title={!item.records ? t("noDataHelperMsg") : inactive ? t("outsideZoom") : ""}
				overlayClassName="font-bold"
				color="#4d4f50"
				placement={isMobile && (document.getElementById(item.id)?.offsetWidth ?? 0) > 150 ? "bottom" : "right"}
				overlayInnerStyle={{ padding: ".25rem .75rem", borderRadius: ".25rem", textAlign: "center" }}
				align={{ offset: isMobile && (document.getElementById(item.id)?.offsetWidth ?? 0) > 150 ? [-10, 0] : [10, 0] }}
			>
				<div className="pointer-events-auto" id={item.id} onClick={() => setTrigger(!trigger)}>
					<Checkbox1Component
						label={<span className="text-black/70">{item.name ?? ""}</span>}
						id={"sub-check-" + item.id}
						checked={checked}
						onChange={() => {
							setChecked(!checked);
							onChange({ ...item, visible: !checked });
						}}
						name=""
						inactive={inactive}
						disabled={!item.records}
					></Checkbox1Component>
				</div>
			</Tooltip>
			<DataLayersOpacitySwitcher item={item} onChange={(i: StyleLayerItem) => onChange(i)}></DataLayersOpacitySwitcher>
		</li>
	);
};

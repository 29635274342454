export const TRANSLATIONS = {
	lv: {
		translation: {
			warningType: "Brīdinājuma veids",
			warningFrom: "Brīdinājuma sākums",
			detourNotes: "Piezīmes",
			legend: "Apzīmējumi",
			search: "Meklēt vietu vai koordinātes",
			data: "Slāņi",
			chooseLayers: "Izvēlēties slāņus...",
			searchLayers: "Meklēt datos un datu slāņos...",
			mapSettings: "Kartes iestatījumi",
			noData: "Nav",
			noDataHelperMsg: "Šobrīd nav aktuālu datu",
			sup20: "Reāllaika dati par pēdejām 20 minūtēm",
			sup60: "Reāllaika dati par pēdejām 60 minūtēm",
			basemap: "Pamatkarte",
			otherSettings: "Citi iestatījumi",
			showLayerSource: "Rādīt datu slāņu avotus",
			baseOpacity: "Pamatkartes caurspīdīgums",
			showLegend: "Rādīt leģendu",
			save: "Saglabāt",
			showPanning: "Rādīt panoramēšanu",
			searchTooltip: "Meklēšanā iespējams norādīt arī koordinātes",
			searchTooltipHelp: "Mēklēšana iespējama koordinātēs, piemēram, 56.94 24.10",
			panControl: "Panoramēšana",
			addUnplanned: "Pievienot neplānotu notikumu",
			addPlanned: "Pievienot plānotu notikumu",
			addStatic: "Pievienot statisku objektu",
			attributions: "Datu avoti",
			dataSources: "Datu kopas",
			zoomIn: "Pietuvināt",
			zoomOut: "Attālināt",
			date: "Datums",
			outsideZoom: "Pietuviniet apskatei",
			layers: "Slāņi",
			pageTitle: "Latvijas Nacionālais Piekļuves punkts - Satiksmes informācijas karte",
			dataLayers: "Datu slāņi",
			searchInLayers: "Meklēt slāņos",
			bubbleMessageNone: "Nav",
			bubbleMessageEvents: "Notikumu",
			bubbleMessageFew: "Maz",
			bubbleMessageEventsOpt: "Satiksmes notikumu",
			bubbleMessageMedium: "Vidējs",
			bubbleMessagesAmountOfEvents: "Satiksmes notikumu skaits",
			bubbleMessageMany: "Daudz",
			bubbleMessageEventsOpt2: "Satiksmes notikumu",
			true: "Jā",
			false: "Nē",
			returnToDefaultView: "Atgriezties uz Latvijas kopskatu",
			srtiTooltip: "Ar drošību saistīta satiksmes informācija",
			tipLabel: "Attiestatīt rotāciju",
			source: "Avoti",
			addPointTooltip: "Pievienot punktveida objektu",
			addLineTooltip: "Pievienot līnījveida objektu",
			addPolyTooltip: "Iezīmēt vietu līnījveida objektiem",
		},
	},
	en: {
		translation: {
			warningType: "Warning type",
			warningFrom: "Warning from",
			detourNotes: "Notes",
			legend: "Legend",
			search: "Search place or coordinates",
			data: "Layers",
			chooseLayers: "Choose layers...",
			searchLayers: "Search within data and data layers...",
			mapSettings: "Map settings",
			noData: "No data",
			noDataHelperMsg: "No current data",
			sup20: "Real-time data about last 20 minutes",
			sup60: "Real-time data about last 60 minutes",
			basemap: "Basemap",
			otherSettings: "Other settings",
			showLayerSource: "Show layers' data sources",
			baseOpacity: "Basemap transparency",
			showLegend: "Show legend",
			save: "Save",
			showPanning: "Show pan button",
			searchTooltip: "You can use coordinates as well",
			searchTooltipHelp: "Format for searching for coordinates is 56.94 24.10",
			panControl: "Pan zoom",
			addUnplanned: "Add an unscheduled event",
			addPlanned: "Add a scheduled event",
			addStatic: "Add a static object",
			attributions: "Data sources",
			dataSources: "Data sources",
			zoomIn: "Zoom in",
			zoomOut: "Zoom out",
			date: "Date",
			outsideZoom: "Outside zoom range",
			layers: "Layers",
			pageTitle: "Latvian National Access Point - Transport Data Map",
			dataLayers: "Data layers",
			searchInLayers: "Search layers",
			bubbleMessageNone: "No",
			bubbleMessageEvents: "Events",
			bubbleMessageFew: "Few",
			bubbleMessageEventsOpt: "Traffic incidents",
			bubbleMessageMedium: "Medium",
			bubbleMessagesAmountOfEvents: "Amount of events",
			bubbleMessageMany: "Lots",
			bubbleMessageEventsOpt2: "Of Events",
			true: "Yes",
			false: "No",
			returnToDefaultView: "Return to overview",
			srtiTooltip: "Safety-related traffic information",
			tipLabel: "Reset rotation",
			source: "Source",
			addPointTooltip: "Add point object",
			addLineTooltip: "Add line object",
			addPolyTooltip: "Draw area for line objects",
		},
	},
};
